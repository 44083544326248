<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:54:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 15:07:58
-->
<template>
  <el-card class="box">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本参数" name="base">
        <div class="edit-row">
          <label>ICP备案号</label>
          <div class="editable">
            <span class="ak" v-if="!visible.icp" @click="onEdit('icp')">{{
              settings.icp
            }}</span>
            <div class="value" v-if="visible.icp">
              <el-input
                v-model="saveObj.icp"
                class="input"
                placeholder=""
                size="small"
              ></el-input>
              <el-button type="primary" size="small" @click="onSubmit('icp')"
                >确定</el-button
              >
              <el-button
                type="primary"
                link
                size="small"
                @click="onCancel('icp')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="邮箱" name="email">
        <div class="edit-row">
          <label>启动错误通知</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.emailErrorPush"
              @click="onEdit('emailErrorPush')"
              >{{ settings.emailErrorPush ? settings.emailErrorPush : "空"
              }}<small>(1:启动，0:否)</small></span
            >
            <div class="value" v-if="visible.emailErrorPush">
              <el-input
                v-model="saveObj.emailErrorPush"
                class="input"
                placeholder=""
                
              ></el-input>
              <el-button
                type="primary"
                size="small"
                @click="onSubmit('emailErrorPush')"
                >确定</el-button
              >
              <el-button
                type="primary"
                link
                size="small"
                @click="onCancel('emailErrorPush')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
        <div class="edit-row">
          <label>邮箱地址</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.emailAccount"
              @click="onEdit('emailAccount')"
              >{{ settings.emailAccount ? settings.emailAccount : "空" }}</span
            >
            <div class="value" v-if="visible.emailAccount">
              <el-input
                v-model="saveObj.emailAccount"
                class="input"
                placeholder=""
                
              ></el-input>
              <el-button
                type="primary"
                size="small"
                @click="onSubmit('emailAccount')"
                >确定</el-button
              >
              <el-button
                type="primary"
                link
                size="small"
                @click="onCancel('emailAccount')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
        <div class="edit-row">
          <label>邮箱Host</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.emailHost"
              @click="onEdit('emailHost')"
              >{{ settings.emailHost ? settings.emailHost : "空" }}</span
            >
            <div class="value" v-if="visible.emailHost">
              <el-input
                v-model="saveObj.emailHost"
                class="input"
                placeholder=""
                
              ></el-input>
              <el-button type="primary" size="small" @click="onSubmit('emailHost')"
                >确定</el-button
              >
              <el-button type="primary"
                link size="small" @click="onCancel('emailHost')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
        <div class="edit-row">
          <label>邮箱Port</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.emailPort"
              @click="onEdit('emailPort')"
              >{{ settings.emailPort ? settings.emailPort : "空" }}</span
            >
            <div class="value" v-if="visible.emailPort">
              <el-input
                v-model="saveObj.emailPort"
                class="input"
                placeholder=""
                
              ></el-input>
              <el-button type="primary" size="small" @click="onSubmit('emailPort')"
                >确定</el-button
              >
              <el-button type="primary" link size="small" @click="onCancel('emailPort')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
        <div class="edit-row">
          <label>邮箱密码</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.emailPassword"
              @click="onEdit('emailPassword')"
              >{{
                settings.emailPassword ? settings.emailPassword : "空"
              }}</span
            >
            <div class="value" v-if="visible.emailPassword">
              <el-input
                v-model="saveObj.emailPassword"
                class="input"
                placeholder=""
                
              ></el-input>
              <el-button
                type="primary"
                size="small"
                @click="onSubmit('emailPassword')"
                >确定</el-button
              >
              <el-button
                type="primary" link
                size="small"
                @click="onCancel('emailPassword')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
        <div class="edit-row">
          <label>接收错误通知邮箱</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.errorToMailAddress"
              @click="onEdit('errorToMailAddress')"
              >{{
                settings.errorToMailAddress ? settings.errorToMailAddress : "空"
              }}</span
            >
            <div class="value" v-if="visible.errorToMailAddress">
              <el-input
                v-model="saveObj.errorToMailAddress"
                class="input"
                placeholder=""
                
              ></el-input>
              <el-button
              type="primary"
                size="small"
                @click="onSubmit('errorToMailAddress')"
                >确定</el-button
              >
              <el-button
              type="primary" link
                size="small"
                @click="onCancel('errorToMailAddress')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="验证码" name="sms">
        <div class="edit-row">
          <label>验证码IP单日限制次数</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.smsCaptchaLimitByIP"
              @click="onEdit('smsCaptchaLimitByIP')"
              >{{
                settings.smsCaptchaLimitByIP
                  ? settings.smsCaptchaLimitByIP
                  : "空"
              }}</span
            >
            <div class="value" v-if="visible.smsCaptchaLimitByIP">
              <el-input
                v-model="saveObj.smsCaptchaLimitByIP"
                class="input"
                placeholder=""
                
              ></el-input>
              <el-button
              type="primary"
                size="small"
                @click="onSubmit('smsCaptchaLimitByIP')"
                >确定</el-button
              >
              <el-button
              type="primary" link
                size="small"
                @click="onCancel('smsCaptchaLimitByIP')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
        <div class="edit-row">
          <label>验证码手机单日限制次数</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.smsCaptchaLimitByPhone"
              @click="onEdit('smsCaptchaLimitByPhone')"
              >{{
                settings.smsCaptchaLimitByPhone
                  ? settings.smsCaptchaLimitByPhone
                  : "空"
              }}</span
            >
            <div class="value" v-if="visible.smsCaptchaLimitByPhone">
              <el-input
                v-model="saveObj.smsCaptchaLimitByPhone"
                class="input"
                placeholder=""
                
              ></el-input>
              <el-button
              type="primary"
                size="small"
                @click="onSubmit('smsCaptchaLimitByPhone')"
                >确定</el-button
              >
              <el-button
              type="primary" link
                size="small"
                @click="onCancel('smsCaptchaLimitByPhone')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="百度地图" name="map">
        <div class="edit-row">
          <label>AppKey</label>
          <div class="editable">
            <span
              class="ak"
              v-if="!visible.bmapWebAppKey"
              @click="onEdit('bmapWebAppKey')"
              >{{
                settings.bmapWebAppKey ? settings.bmapWebAppKey : "空"
              }}</span
            >
            <div class="value" v-if="visible.bmapWebAppKey">
              <el-input
                v-model="saveObj.bmapWebAppKey"
                class="input"
                placeholder=""
              ></el-input>
              <el-button
                type="primary"
                size="small"
                @click="onSubmit('bmapWebAppKey')"
                >确定</el-button
              >
              <el-button
                type="primary" link
                size="small"
                @click="onCancel('bmapWebAppKey')"
                >取消</el-button
              >
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      settings: {},
      saveObj: {},
      activeName: "base",
      visible: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$http.get("/admin/v1/setting").then((res) => {
        if (res.code == 0) {
          this.settings = res.data;
        }
      });
    },
    onEdit(name) {
      this.visible[name] = true;
      this.saveObj[name] = this.settings[name];
    },
    onCancel(name) {
      this.visible[name] = false;
    },
    onSubmit(name) {
      console.log(name);
      var val = this.saveObj[name];
      var data = {
        name: name,
        value: val,
      };
      this.$http.post("admin/v1/setting/save", data).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.settings[name] = this.saveObj[name];
          this.onCancel(name);
        }
      });
    },
  },
};
</script>

<style>
.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  font-size: 14px;
}
.edit-row label {
  width: 160px;
  text-align: right;
  color: var(--text-label-color);
}
.edit-row .editable {
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.edit-row span.ak {
  border-bottom: 2px dashed var(--border-color);
  cursor: pointer;
}
.edit-row span.ak small {
  color: var(--text-label-color);
}
.editable .input {
  margin-right: 10px;
  width: 360px;
}
</style>